import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./FormPage.css";
import { StoryContext } from "./StoryContext";

const FormPage = () => {
  const { formData, setFormData } = useContext(StoryContext);
  const [fadeIn, setFadeIn] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const prompts = [
    { question: "Name", type: "input", placeholder: "Enter child's name" },
    {
      question: "Personality",
      type: "select",
      options: ["Brave", "Shy", "Smart", "Funny", "Scared"],
    },
    {
      question: "Environment",
      type: "select",
      options: [
        "Desert",
        "Space",
        "Mountains",
        "Underwater",
        "Jungle",
        "Snow",
        "Robotics World",
        "Enchanted Forest",
        "Mystical Mountains",
        "Secret Garden",
        "Hidden Lagoon",
        "Tropical Island",
        "Crystal Cavern",
        "Candy Land",
        "Rainbow Valley",
        "Underwater Kingdom",
        "Winter Wonderland",
        "Magical Meadow",
        "Volcanic Island",
        "Desert Oasis",
        "Ancient Ruins",
        "Haunted Mansion",
        "Glowing Caves",
        "Dragon's Lair",
        "Silent Swamp",
        "Emerald Jungle",
      ],
    },
    {
      question: "Adventure",
      type: "select",
      options: [
        "Explorer",
        "Treasure Hunter",
        "Dinosaurs",
        "Time Travel",
        "Pirates",
        "Wild West",
        "Enchanted Castle",
        "Knights vs Dragons",
        "Robots",
        "The Great Adventure",
        "Mystery Quest",
        "Rescue Mission",
        "Hidden Secrets",
        "Treasure Hunt",
        "Hero's Journey",
        "Escape from Danger",
        "Magic Quest",
        "The Lost Expedition",
        "Guardians of the Realm",
        "Race Against Time",
        "The Brave Explorer",
        "Defenders of the Land",
        "Hidden Treasures",
        "Quest for the Artifact",
        "The Epic Rescue",
        "The Guardian's Challenge",
        "Mystery of the Lost Map",
        "The Secret Mission",
        "Escape the Maze",
        "Ancient Relics",
        "The Cursed Expedition",
        "The Magic Tournament",
      ],
    },
    {
      question: "Companion",
      type: "select",
      options: [
        "Dog",
        "Cat",
        "Mouse",
        "Hawk",
        "Dragon",
        "Lizard",
        "Owl",
        "Unicorn",
        "Rabbit",
        "Monkey",
        "Fox",
        "Panda",
        "Phoenix",
        "Elephant",
        "Wolf",
        "Parrot",
        "Bear",
        "Cheetah",
        "Lion",
        "Fairy",
        "Knight",
        "Horse",
        "Penguin",
      ],
    },
    {
      question: "Favorite Color",
      type: "select",
      options: ["Red", "Blue", "Green", "Yellow", "Purple", "Pink", "Orange"],
    },
    {
      question: "Story Length",
      type: "select",
      options: ["Small", "Medium", "Large"],
    },
  ];

  useEffect(() => {
    setFadeIn(true);
  }, []);

  const handleNext = () => {
    if (currentStep < prompts.length - 1) {
      setCurrentStep(currentStep + 1);
    } else if (isFormComplete) {
      navigate("/story");
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleBackToHome = () => {
    navigate("/home");
  };

  const handleChange = (e) => {
    const key = prompts[currentStep].question;
    setFormData((prevData) => ({ ...prevData, [key]: e.target.value }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isFormComplete = Object.values(formData).every((value) => value !== "");

  const handleSettingsClick = () => {
    navigate("/settings");
  };

  return (
    <div className={`formpage-container ${fadeIn ? "fade-in" : ""}`}>
      <img
        src={process.env.PUBLIC_URL + "/formpage/formpagebackground.png"}
        alt="Form Background"
        className="form-background"
      />

      {/* Back Arrow */}
      <button className="back-arrow" onClick={handleBackToHome}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
        </svg>
      </button>

      <div className="form-box">
        <div className="form-navigation">
          {currentStep > 0 && (
            <button className="form-back-button" onClick={handleBack}>
              &#8249;
            </button>
          )}
          <div className="form-question">{prompts[currentStep].question}</div>
        </div>
        {prompts[currentStep].type === "input" ? (
          <input
            type="text"
            value={formData[prompts[currentStep].question]}
            onChange={handleChange}
            placeholder={prompts[currentStep].placeholder}
            className="form-input"
          />
        ) : (
          <select
            value={formData[prompts[currentStep].question]}
            onChange={handleChange}
            className="form-select"
            size={7}
          >
            <option value="" disabled>
              Select an option
            </option>
            {prompts[currentStep].options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        )}
        <div className="form-buttons">
          <button
            onClick={handleNext}
            className={`form-next-button ${
              isFormComplete && currentStep === prompts.length - 1
                ? "generate-button"
                : ""
            }`}
          >
            {isFormComplete && currentStep === prompts.length - 1
              ? "Generate!"
              : "Next"}
          </button>
          <button className="settings-button" onClick={handleSettingsClick}>
            Advanced
          </button>
        </div>
      </div>

      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button className="toggle-sidebar" onClick={toggleSidebar}>
          {isSidebarOpen ? "Close" : "Summary"}
        </button>
        <div className="sidebar-content">
          <h3>Summary</h3>
          <ul>
            {Object.keys(formData).map((key, index) => (
              <li key={index}>
                <strong>{key}:</strong> {formData[key] || "Not selected"}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FormPage;